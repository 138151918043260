<template>
  <va-card>
    <va-button color="purple" icon="entypo entypo-plus" @click="initiateImport()">Import</va-button>
    <va-modal
      class="flex xs12"
      v-model="importModal"
      title="Import File"
      size='small'
      okText=""
      cancelText=""
      @cancel="cancelImport()"
      noEscDismiss>      
      <va-button small color="danger" icon="entypo entypo-cancel" class="model_cancel" @click="cancelImport()"></va-button><br>
      <div style="text-align: center;">
        <img class="icon" src="@/assets/upload-subscription.png" style="width: 200px; height: 200px;" />
      </div>
      <div style="text-align: center;">
        <div v-if="bulkData" style="text-align: right;">
          <div class="row">
            <va-file-upload
              v-if="bulkData"
              type="single"
              v-model="importData"
            />
            <a href="#" v-if="bulkData" style="margin-left: 30px; margin-top: 5px;" @click="downloadFormat()">Download Format</a>
          </div>
          <va-button type="submit" class="my-0" @click.prevent="saveImport()">Save</va-button>
          <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="cancelImport()">Cancel</va-button>
        </div>
      </div>
    </va-modal>
  </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)
export default {
  name: 'yt',
  data () {
    return {
      importModal: false,
      make_import: false,
      bulkData: false,
      importData: [],
    }
  },
  methods: {
    initiateImport () {
      this.importModal = true
      this.makeImport()
    },
    downloadFormat () {
      this.cancelImport()
      var rowKeys = {
        description: 'Description',
        actors: 'Actors',
        genres: 'Genres',
        service_type: 'Service Type',
        language: 'Language',
        audio_lang: 'Audio Lang',
        language_title: 'Language Title',
        business_type: 'Business Type',
        country: 'Country',
        link_url: 'Link Url',
        web_url: 'Web Url',
        deeplink_url: 'Deeplink Url',
        vertical_image: 'Vertical Image',
        horizontal_image: 'Horizontal Image',
        releasedate: 'Release Date',
        on_air: 'On Air',
        banner: 'Banner',
        rating: 'Rating',
        watchcount: 'Watchcount',
        rank: 'Rank',
        age_limit: 'Age Limit',
        tags: 'Tags',
        director: 'Director',
        is_subtitle: 'Is Subtitle',
        title: 'Title',
        sc_type: 'SC Type',
        content_id: 'Content ID',
        duration: 'Duration',
      }
      var list = []
      exportToFile(list, rowKeys, 'YTFormat', 'CSV')
    },
    cancelImport () {
      this.importModal = false
      this.make_import = false
      this.bulkData = false
      this.bulkCreate = false
      this.mode = ''
      this.msgFlag = false
      this.importData = []
	 },
     makeImport () {
      this.bulkData = true
      this.make_import = true
    },
    saveImport () {
      if (this.importData && this.importData.length > 0) {
        var file_content = this.importData[0]
        var reference = this.$http
        var ref = this
        var str = file_content.type.split('/')
        this.selectedFile = file_content
        if (typeof (FileReader) !== 'undefined') {
          var reader = new FileReader()
          reader.onload = function (e) {
            var headerKeys = ['Description', 'Actors', 'Genres', 'Service Type', 'Language', 'Audio Lang', 'Language Title', 'Business Type', 'Country', 'Link Url', 'Web Url', 'Deeplink Url', 'Vertical Image', 'Horizontal Image', 'Release Date', 'On Air', 'Banner', 'Rating', 'Watchcount', 'Rank', 'Age Limit', 'Tags', 'Director', 'Is Subtitle', 'Title', 'SC Type', 'Content ID', 'Duration']
            var yt_list = new Array()
            var rows = e.target.result.split('\n')
            var proceedFlag = false
            for (var i = 0; i < rows.length; i++) {
              if (i == 0) {
                var cells = rows[i].split(',')
                var compareArray = JSON.stringify(headerKeys) == JSON.stringify(cells).replace(/\\r/g, '')
                if (compareArray) {
                  proceedFlag = true
                } else {
                  return Vue.notify({ text: "Please don't remove or modify the columns", type: 'error' })
                }
              }
              if (proceedFlag && (i > 0)) {
                var cells = rows[i].split(',')
                if (cells.length > 1) {
                  var yt = {}
                  yt.description = cells[0]
                  yt.actors = cells[1]
                  yt.genres = cells[2]
                  yt.service_type = cells[3]
                  yt.language = cells[4]
                  yt.audio_lang = cells[5]
                  yt.language_title = cells[6]
                  yt.business_type = cells[7]
                  yt.country = cells[8]
                  yt.link_url = cells[9]
                  yt.web_url = cells[10]
                  yt.deeplink_url = cells[11]
                  yt.vertical_image = cells[12]
                  yt.horizontal_image = cells[13]
                  yt.releasedate = cells[14]
                  yt.on_air = cells[15]
                  yt.banner = cells[16]
                  yt.rating = cells[17]
                  yt.watchcount = cells[18]
                  yt.rank = cells[19]
                  yt.age_limit = cells[20]
                  yt.tags = cells[21]
                  yt.director = cells[22]
                  yt.is_subtitle = cells[23]
                  yt.title = cells[24]
                  yt.sc_type = cells[25]
                  yt.content_id = cells[26]
                  yt.duration = cells[27].replace(/\\r/g, '')
                  yt_list.push(yt)
                }
              }
            }
            var payload = {
              yt_list: yt_list,
            }
            if (yt_list.length > 0) {
              const loader = Vue.$loading.show({ width: 40, height: 40 })
              ref.$http.post(config.menu.host + '/yt/content', payload).then(response => {
			                loader.hide()
			                ref.cancelImport()
			                if (response && response.body) {
			                  Vue.notify({ text: response.body, type: 'success' })
			                }
			              }, error => {
			                loader.hide()
			                if (error && error.body) {
			                  Vue.notify({ text: error.body, type: 'error' })
			                }
			              })
            } else {
              return Vue.notify({ text: 'File is empty.', type: 'error' })
            }
          }
          reader.readAsText(this.selectedFile)
        } else {
          return alert('This browser does not support HTML5.')
        }
      } else {
        return Vue.notify({ text: 'No File Found.', type: 'error' })
      }
    },
  },
}
</script>

<style scoped>

.model_cancel {
  float: right;
  margin-top: -8%;
  margin-right: -3%;
}

a:hover {
  text-decoration: underline;
}
</style>
